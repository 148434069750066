import { render, staticRenderFns } from "./Businesssuperretail.vue?vue&type=template&id=0510fe18&scoped=true"
import script from "./Businesssuperretail.vue?vue&type=script&lang=js"
export * from "./Businesssuperretail.vue?vue&type=script&lang=js"
import style0 from "./Businesssuperretail.vue?vue&type=style&index=0&id=0510fe18&prod&scoped=scoped&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0510fe18",
  null
  
)

export default component.exports